import React, { useEffect, useState } from "react";
import style from "./Contact.module.scss";
import { RiLinkedinLine } from "react-icons/ri";
import { AiOutlineMail } from "react-icons/ai";
import { BsWhatsapp } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as emailjs from "emailjs-com";

const Contact = () => {
  const [error, setError] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setError(false);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("* Name field is required"),
      email: Yup.string()
        .trim()
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Invalid email format, example@domain.com"
        )
        .required("* Email field is required"),
      message: Yup.string().required("* Message field is required"),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      emailjs
        .send(
          process.env.REACT_APP_SERVICE_EMAIL_JS,
          process.env.REACT_APP_TEMPLATE_EMAIL_JS,
          values,
          process.env.REACT_APP_KEY_EMAIL_JS
        )
        .then(
          (result) => {
            console.log(result.text);
            formik.resetForm();
            handleShow();
          },
          (error) => {
            setError(true);
            console.log(error.text);
          }
        );
    },
  });

  useEffect(() => {
    if (show) {
      setTimeout(() => setShow(false), 5000);
    }
  }, [show]);

  return (
    <section id="contact">
      <div className={style.ctn}>
        <h1 className={style.titleSup}>CONTACT</h1>

        <div className={style.form}>
          <div className={style.ctnSupDate}>
            <a
              className={style.a}
              href="whatsapp://send?phone=5492494250643"
              target="_blank"
              rel="noreferrer"
            >
              <div className={style.date}>
                <BsWhatsapp className={style.icon} />
                <div className={style.ctnTxt}>
                  <p className={style.txt}>WhatsApp</p>
                  <p className={style.txt2}>+5492494250643</p>
                </div>
              </div>
            </a>

            <a className={style.a} href="mailto:davis_heredia@hotmail.com">
              <div className={style.date}>
                <AiOutlineMail className={style.icon} />
                <div className={style.ctnTxt}>
                  <p className={style.txt}>Email</p>
                  <p className={style.txt2}>davis_heredia@hotmail.com</p>
                </div>
              </div>
            </a>

            <a
              className={style.a}
              href="https://www.linkedin.com/in/david-heredia-91625b24/"
              target="_blank"
              rel="noreferrer"
            >
              <div className={style.date}>
                <RiLinkedinLine className={style.icon} />
                <div className={style.ctnTxt}>
                  <p className={style.txt}>Linkedin</p>
                  <p className={style.txt2}>David Heredia</p>
                </div>
              </div>
            </a>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <label className={style.title}>
              Name
              {formik.touched.name && formik.errors.name ? (
                <p className={style.error}>{formik.errors.name}</p>
              ) : null}
            </label>
            <input
              className={style.input}
              id="name"
              name="name"
              type="text"
              autoComplete="off"
              onChange={formik.handleChange}
              value={formik.values.name}
              onBlur={formik.handleBlur}
            />

            <label className={style.title}>
              Email
              {formik.touched.email && formik.errors.email ? (
                <p className={style.error}>{formik.errors.email}</p>
              ) : null}
            </label>
            <input
              className={style.input}
              id="email"
              name="email"
              autoComplete="off"
              onChange={formik.handleChange}
              value={formik.values.email}
              onBlur={formik.handleBlur}

            />

            <label className={style.title}>
              Message
              {formik.touched.message && formik.errors.message ? (
                <p className={style.error}>{formik.errors.message}</p>
              ) : null}
            </label>
            <textarea
              className={style.textarea}
              id="message"
              name="message"
              autoComplete="off"
              onChange={formik.handleChange}
              value={formik.values.message}
              onBlur={formik.handleBlur}
            />

            <button className={style.btn} type="submit">
              {" "}
              Send Message
            </button>
          </form>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {error ? "Error sending message" : "Message sent"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error
            ? "please send an email to: davis_heredia@hotmail.com"
            : "I will be in touch with you soon, thank you very much for contacting me!"}
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default Contact;
